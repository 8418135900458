<div *ngIf="(role === 'Customer Admin' || role === 'SGRE Admin')"
	class="container-fluid">
	<app-table-template [columns]="columns"
		[tableData]="sgredetails"
		[allowPagination]="true"
		[filterLegalEntity]="true"
		[rowsPerPage]="10"
		(filteredData)="onFilter($event)"
		(navigateClickEvent)="navigate($event)">
	</app-table-template>
</div>
